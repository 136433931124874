import React, { useState, useEffect } from "react";
import "./OurCustomerStory.scss" ;
import axios from "axios";
import { Link } from "react-router-dom";

const OurCustomerStory = ({ isShow, isShow1 }) => {
  const [blogs, setBlogs] = useState([]);
  const [selectedOption, setSelectedOption] = useState("most recent");

  useEffect(() => {
    axios
      .get("/getBlogs?isPublished=true")
      .then((response) => {
        const blogData = response.data.blogs;
        setBlogs(blogData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Filter blogs based on the selected category
  const filteredBlogs =
    selectedOption === "most recent"
      ? blogs
      : blogs.filter((blog) => blog.category === selectedOption);

  const staticCards = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=600",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-1",
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=600",
      category: "VAPT",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-2",
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=600",
      category: "VAPT",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-3",
    },
  ];

  // useEffect(() => {
  //   // Scroll to the top of the page when the component mounts
  //   window.scrollTo(0, 0);
  // });

  return (
    <div className="recent-blogs mt-10 w-full">
      <div className="flex flex-col items-center">
        <Link to="/About">
          <div className="flex items-center justify-center mb-4">
            <p className="text-4xl md:text-5xl text-center lg:text-[45px] font-extrabold text-[#545454]">
              Our <span className="text-[#044aad]">Customer Story</span>
            </p>
          </div>
        </Link>
      </div>

      <div className="cards flex justify-center md:m-10 text-2xl">
        {staticCards.length === 0 ? (
          <p className="main-heading my-14">
            No blogs available for the {selectedOption} category.
          </p>
        ) : (
          staticCards.map((card) => (
            <div
              className="col-lg-4 card1 overflow-hidden bg-gray-30 rounded-lg shadow-lg"
              key={card.id}
              data-aos="zoom-in"
            >
              <Link to="/About">
                <div className=" h-48">
                  <img
                    src={card.image}
                    alt={`Blog ${card.id}`}
                    className="object-cove w-full h-full"
                  />
                </div>
                <div className="hr my-4"></div>
                <div className="content p-4">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: card.description,
                    }}
                    className="desc"
                  ></p>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OurCustomerStory;