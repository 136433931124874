import React, { useState, useEffect } from "react";
import "./CyberNews.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const CyberNews = ({ isShow, isShow1 }) => {
  const [blogs, setBlogs] = useState([]);
  const [selectedOption, setSelectedOption] = useState("most recent");

  useEffect(() => {
    axios
      .get("/getBlogs?isPublished=true")
      .then((response) => {
        const blogData = response.data.blogs;
        setBlogs(blogData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Filter blogs based on the selected category
  const filteredBlogs =
    selectedOption === "most recent"
      ? blogs
      : blogs.filter((blog) => blog.category === selectedOption);

  const staticCards = [
    {
      id: 1,
      title: "News Title",
      category: "VAPT",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-1",
    },
    {
      id: 2,
      title: "News Title",
      category: "VAPT",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-2",
    },
    {
      id: 3,
      title: "News Title",
      category: "VAPT",
      description:
        "10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy.",
      time: "Published on 5th Oct 2023",
      slug: "card-3",
    },
  ];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  });
  return (
    <div className="recent-blogs  mt-[60px] w-full  ">
      <div className="flex flex-col items-center">
        <Link to="/About">
          <div className="flex items-center justify-center mb-4">
            <p className="text-4xl md:text-5xl lg:text-[45px] font-extrabold text-[#545454]">
              Cyber <span className="text-[#044aad]">News</span>
            </p>
          </div>
        </Link>
        <div><p className="lg:text-[24px] text-[#545454] font-semibold mb-10 text-center">Cybersecurity Trends: Covering the Most Recent Developments in Security</p></div>
      </div>

      <div className="cards flex justify-center  md:m-[50px]  text-2xl">
        {staticCards.length === 0 ? (
          <p className="main-heading my-14">
            No blogs available for the {selectedOption} category.
          </p>
        ) : (
          staticCards.map((card) => (
            <div className="col-lg-4  card1 " key={card.id} data-aos="zoom-in">
              <Link to="/About">
                <div className="heading">
                  <h2 className="text-4xl main-heading">{card.title}</h2>
                  <h4 className="px-1 mt-4 text-lg desc">{card.category}</h4>
                  <h5 className="pt-2 text-md">{card.time}</h5>
                </div>
                <div className="hr my-4"></div>
                <div className="content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: card.description,
                    }}
                    className="desc"
                  ></p>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CyberNews;
