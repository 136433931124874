import React from "react";

export const Middle = () => {
  return (
    <div className="grid grid-cols-1 mt-[70px]  lg:grid-cols-2 p-5 lg:p-10 bg-blue-800 bg-opacity-10">
      <div className="flex flex-col items-stretch">
        <div className="text-blue-800 mx-4 text-4xl md:text-5xl lg:text-6xl md:mt-10 ml-6 font-bold">
          <span className="text-[#545454]">What is</span>
          <br /> ISO 29147?
        </div>
        <div className="text-neutral-600 mx-6 lg:w-[85%] mt-3 text-lg md:text-xl">
          ISO 29147, an international standard, offers programmatic guidance for
          effective vulnerability disclosure initiatives.
        </div>
      </div>
      <div className="flex flex-col items-stretch">
        <div className="text-[#545454] m-6 text-justify md:text-xl font-medium">
          The purpose of ISO 29147 is to assist organizations in mitigating
          security vulnerabilities, fostering collaboration between entities and
          security researchers. This standard outlines a framework for
          developing and executing vulnerability disclosure programs. It
          constitutes a segment of the International Organization for
          Standardization's series dedicated to diverse facets of information
          security.
        </div>
      </div>
    </div>
  );
};
