import React from "react";
import Middleimg from "../assets/Middleimg.png";
import Middleimg2 from "../assets/Middleimg2.png";
import Middleimg3 from "../assets/Middleimg3.png";

export const Middle2 = () => {
  return (
    <div className="bg-[#004aad]  w-full px-9  py-9 ">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="flex  gap-2.5 items-center">
          <div>
            <img
              loading="lazy"
              src={Middleimg}
              alt=""
              className="aspect-[0.97] object-contain  object-center w-[100px] h-[70px] overflow-hidden shrink-0 max-w-full"
            />
          </div>
          <div className="flex flex-col items-stretch">
            <div className="text-white text-2xl  font-bold whitespace-nowrap">
              Why Vulfosec?
            </div>
            <div className="text-white text-base font-medium mt-2">
              We provide the fastest channel to achieve ISO29147 and NIST
              guidelines
            </div>
          </div>
        </div>
        <div className="flex  gap-2.5 items-center">
          <div>
            <img
              loading="lazy"
              src={Middleimg2}
              alt=""
              className="aspect-[0.97] object-contain  object-center w-[150px] h-[70px] overflow-hidden shrink-0 max-w-full"
            />
          </div>
          <div className="flex flex-col items-stretch">
            <div className="text-white text-2xl  font-bold whitespace-nowrap">
              Establish Trust
            </div>
            <div className="text-white text-base font-medium mt-2">
              Instill confidence with proactive cybersecurity, fostering trust
              among users, customers, partners, and stakeholders.
            </div>
          </div>
        </div>
        <div className="flex  gap-2.5 items-center">
          <div>
            <img
              loading="lazy"
              src={Middleimg3}
              alt=""
              className="aspect-[0.97] object-contain  object-center w-[50px] lg:w-[100px] h-[70px] overflow-hidden shrink-0 max-w-full"
            />
          </div>
          <div className="flex flex-col w-[310px]">
            <div className="text-white  text-xl  font-bold ">
              Researchers's Haven, for Reporting Vulnerabilities
            </div>
            <div className="text-white text-base font-medium mt-2">
              Fostering researchers in a collaborative, secure environment for
              vulnerability reporting and community contribution.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
