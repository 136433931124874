import * as React from "react";
import About1 from "../assets/About1.png";
import Demo from "../assets/Demo.jpg";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import CyberNews from "./CyberNews/CyberNews";
import OurCustomerStory from "./OurCustomerStory/OurCustomerStory";

const About = () => {
  return (
    <>
      <NavBar />
      <div>
        <div className=" lg:mx-16">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className=" xs:w-[100%] s:w-[50%] lg:w-[35%] ">
              <div className="text-3xl text-center lg:text-start lg:text-6xl leading-10 font-extrabold">
                <span className="text-[#545454]  lg:flex">Building a</span>{" "}
                <span className="text-[#044aad]">Safer Internet</span>
              </div>
              <div className="text-xl text-center lg:text-start leading-10 lg:text-[28px] text-[#545454]">
                <p>
                  In our cyber security marketplace, we fortify your digital
                  presence. Services include threat detection, compliance, and
                  data protection.
                </p>
              </div>
            </div>
            <div className="">
              <img src={About1} alt="" className="w-[95%] " />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col">
          <div className="text-5xl text-[#545454] text-lg:text-start lg:mx-[190px] mb-4 font-extrabold">
            About <span className="text-[#004aad]">Vulfosec</span>
          </div>
          <div className="self-center w-full max-w-[1148px] mt-2 max-md:max-w-full">
            <div className="flex flex-col justify-center items-center md:flex-row">
              <div className="md:w-[46%]">
                <div className="w-[405px] shrink-0 max-w-full mt-2 mx-auto md:mt-10">
                  <img src={Demo} alt="" className="max-w-full h-auto" />
                </div>
              </div>
              <div className="md:w-[54%] md:ml-5">
                <div className="text-neutral-600 text-justify text-2xl mt-5 md:mt-0 max-w-full">
                  Vulfosec is your trusted cybersecurity marketplace—a hub where
                  cybersecurity service providers, product-based companies, and
                  savvy buyers unite. In an ever-evolving digital landscape,
                  Vulfosec simplifies the search for robust security solutions,
                  fostering collaboration and connection within the
                  cybersecurity community. Welcome to Vulfosec, where we secure
                  the digital future together.
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex flex-col mx-3">
          {/* <!-- About Vulfosec --> */}
          <div className="text-blue-800 text-5xl font-extrabold lg:mb-5  mt-32 text-center lg:text-start max-lg:text-4xl ">
            <span className="text-[#545454]">
              About<span className="text-[#004aad]"> Vulfosec</span>
            </span>
          </div>

          {/* <!-- About Vulfosec Content --> */}
          <div className="self-center w-full max-w-[1148px]  ">
            <div className="flex max-lg:flex-col max-lg:items-stretch max-lg:gap-0">
              {/* <!-- Left Content --> */}
              <div className="flex flex-col items-stretch w-[46%] max-lg:w-full max-lg:ml-0">
                <div className="flex w-[285px] lg:w-[405px] flex-col mt-2 mx-auto max-lg:mt-10">
                  <img src={Demo} alt="" />
                </div>
              </div>

              {/* <!-- Right Content --> */}
              <div className="flex flex-col mt-10 lg:mt-0 items-center w-[54%] ml- max-lg:w-full max-lg:ml-0">
                <div className="text-neutral-600 text-center sm:text-justify mx-10 lg:mx-0 text-md  md:2xl lg:text-2xl  mt- max-lg:max-w-full">
                  Vulfosec is your trusted cybersecurity marketplace—a hub where
                  cybersecurity service providers, product-based companies, and
                  savvy buyers unite. In an ever-evolving digital landscape,
                  Vulfosec simplifies the search for robust security solutions,
                  fostering collaboration and connection within the
                  cybersecurity community. Welcome to Vulfosec, where we secure
                  the digital future together.
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Our Story --> */}
          <div className="self-center w-full max-w-[1148px] mt-28 max-lg:max-w-full max-lg:mt-10">
            <div className="gap-5 flex max-lg:flex-col max-lg:items-stretch max-lg:gap-0">
              {/* <!-- Left Content --> */}
              <div className="flex flex-col items-stretch w-[55%] max-lg:w-full max-lg:ml-0">
                <span className="flex grow flex-col items-stretch max-lg:max-w-full max-lg:mt-10">
                  <div className="text-blue-800 text-5xl mb-3 lg:mb-3 text-center lg:text-start font-extrabold max-lg:max-w-full max-lg:text-4xl">
                    <span className="text-neutral-600">
                      Our<span className="text-[#004aad]"> Story</span>
                    </span>
                  </div>
                  <div className="text-neutral-600 text-center sm:text-justify mx-10 lg:mx-0 text-md  md:2xl lg:text-2xl  mt- max-lg:max-w-full">
                    Vulfosec, we're more than just a marketplace; we're the
                    architects of a secure digital future. Our platform is
                    dedicated to bridging the gap between cybersecurity
                    companies and discerning security buyers, creating a vibrant
                    ecosystem where innovation, trust, and collaboration thrive.
                  </div>
                </span>
              </div>

              {/* <!-- Right Content --> */}
              <div className="flex flex-col items-stretch w-[46%] max-lg:w-full max-lg:ml-0">
                <div className="flex w-[285px] lg:w-[405px] flex-col mt-2 mx-auto max-lg:mt-10">
                  <img src={Demo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-blue-800 text-5xl font-extrabold  mt-28 text-center lg:text-start max-lg:text-4xl ">
            <span className="text-[#545454]">
              Our<span className="text-[#004aad]"> Vision</span>
            </span>
          </div>

          {/* <!-- About Vulfosec Content --> */}
          <div className="self-center w-full max-w-[1148px]  ">
            <div className="flex max-lg:flex-col max-lg:items-stretch max-lg:gap-0">
              {/* <!-- Left Content --> */}
              <div className="flex flex-col items-stretch w-[46%] max-lg:w-full max-lg:ml-0">
                <div className="flex w-[285px] lg:w-[405px] flex-col mt-2 mx-auto max-lg:mt-10">
                  <img src={Demo} alt="" />
                </div>
              </div>

              {/* <!-- Right Content --> */}
              <div className="flex flex-col mt-10 lg:mt-0 items-center w-[54%] ml- max-lg:w-full max-lg:ml-0">
              <div className="text-neutral-600 text-center sm:text-justify mx-10 lg:mx-0 text-md  md:2xl lg:text-2xl  mt- max-lg:max-w-full">
                  Empowering Security: Our vision is to empower security
                  companies and security-conscious buyers alike by providing a
                  dynamic marketplace where they can find, connect, and
                  collaborate seamlessly. We envision a world where
                  cybersecurity is accessible and uncompromising, safeguarding
                  digital landscapes from evolving threats.
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Our Story --> */}
          <div className="self-center w-full max-w-[1148px] mt-28 max-lg:max-w-full max-lg:mt-10">
            <div className="gap-5 flex max-lg:flex-col max-lg:items-stretch max-lg:gap-0">
              {/* <!-- Left Content --> */}
              <div className="flex flex-col items-stretch w-[55%] max-lg:w-full max-lg:ml-0">
                <span className="flex grow flex-col items-stretch max-lg:max-w-full max-lg:mt-10">
                  <div className="text-blue-800 text-5xl mb-5 text-center lg:text-start font-extrabold max-lg:max-w-full max-lg:text-4xl">
                    <span className="text-neutral-600">
                      Our<span className="text-[#004aad]"> Mission</span>
                    </span>
                  </div>
                  <div className="text-neutral-600 text-justify mx-10 lg:mx-0 text-xl  md:text-2xl  max-lg:max-w-full">
                    Connecting Excellence: Our mission is to connect
                    cybersecurity service providers and product-based companies
                    with security buyers who seek the best-in-class solutions.
                    We're committed to simplifying the process of securing
                    digital assets by offering a diverse range of services and
                    products, facilitating collaboration, and promoting best
                    practices.
                  </div>
                </span>
              </div>

              {/* <!-- Right Content --> */}
              <div className="flex flex-col items-stretch w-[46%] max-lg:w-full max-lg:ml-0">
                <div className="flex w-[285px] lg:w-[405px] flex-col mt-2 mx-auto max-lg:mt-10">
                  <img src={Demo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-40 mb-40">
          <OurCustomerStory />
        </div>
        <CyberNews />
        <div className="mt-40">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default About;
