import React from "react";
import navImg from "../assets/navImage.png";
import Phone from "../assets/Phone.png";
import Email from "../assets/Email.png";
import Place from "../assets/PlaceMaker.png";
import Facebook from "../assets/Facebook.png";
import Instagram from "../assets/Instagram.png";
import LinkedIn from "../assets/LinkedIn.png";
import Twitter from "../assets/Twitter.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-[#e7e6e6]">
      <div className="flex flex-col md:flex-row   ">
        <div className="flex flex-col  lg:w-[37%] items-center justify-center mt-5">
          <div className="">
            <img loading="lazy" src={navImg} alt="" className="w-[300px]" />
          </div>
          <div className="flex flex-col ">
            <Link to="mailto:corporate@vulfosec.com">
              <div className="flex items-center gap-2 mt-3 max-md:mt-5">
                <img loading="lazy" src={Email} alt="" className="w-6 h-6" />
                <div className="text-neutral-600 text-base font-medium">
                  corporate@vulfosec.com
                </div>
              </div>
            </Link>
            <div className="flex items-center gap-2 mt-2 max-md:mt-3.5">
              <img loading="lazy" src={Phone} alt="" className="w-6 h-6" />
              <Link to="tell:+91 90167 96249">
                <div className="text-neutral-600 text-base font-medium">
                  +91 90167 96249
                </div>
              </Link>
            </div>

            <div className="flex items-center gap-2 mt-2 max-md:mt-4">
              <img loading="lazy" src={Place} alt="" className="w-6 h-6" />
              <div className="text-neutral-600 text-base font-medium">
                Ahmedabad, Gujarat, India
              </div>
            </div>
          </div>
        </div>

        <div className=" flex flex-col md:flex-row items-center mx-10 md:gap-8  lg:gap-40  mt-8 md:items-baseline">
          <div className="">
            <div className=" flex flex-col items-start text-neutral-600">
              <div className="text-[#545454] text-xl font-semibold">
                QUICK LINKS
              </div>
              <Link to="https://company.vulfosec.com" target="_blank">
                <div className="my-1">Companies</div>
              </Link>

              <Link to="https://researcher.vulfosec.com" target="_blank">
                <div className="my-1">Researchers</div>
              </Link>
              <Link to="/Blogs">
                <div className="my-1">
                  <span className="text-[#545454]">Blogs</span>
                </div>
              </Link>

              {/* <div className="my-1">Contact Us</div> */}
            </div>
          </div>

          <div className=" max-md:mt-5 ">
            <div className=" flex flex-col items-start text-neutral-600">
              <div className="text-[#545454] text-xl font-semibold">ABOUT</div>
              <Link to="/">
                <div className="my-1">
                  <span className="text-[#545454]">Careers</span>
                </div>
              </Link>
              <Link to="/">
                <div className="my-1">
                  <span className="text-[#545454]">Cyber News</span>
                </div>
              </Link>
            </div>
          </div>

          <div className="flex flex-col mt-5 ">
            <div className="text-[#545454] text-xl font-semibold">SOCIAL</div>
            <div className="flex items-center gap-2  mt-2">
              <Link
                to="https://www.facebook.com/vulfosec?mibextid=vk8aRt"
                target="_blank"
              >
                <img loading="lazy" src={Facebook} alt="" className="w-6 h-6" />
              </Link>
              <Link
                to="https://www.instagram.com/vulfosec?igsh=YjJzMXB1amhlOXI0"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={Instagram}
                  alt=""
                  className="w-6 h-6"
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/vulfosec/"
                target="_blank"
              >
                <img loading="lazy" src={LinkedIn} alt="" className="w-6 h-6" />
              </Link>
              <Link
                to="https://x.com/vulfosec?t=gVOF48shaY_LA8kGuSXDfg&s=08"
                target="_blank"
              >
                <img loading="lazy" src={Twitter} alt="" className="w-6 h-6" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="text-[#545454] pb-7 mt-7 text-center text-lg font-semibold">
        © 2024 Vulfosec Cyber Security Services LLP. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
