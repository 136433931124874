import React from "react";
import { Link } from "react-router-dom";

export const Head = () => {
  return (
    <>
      <div className="bg-[#004aad] flex font-semibold sm:h-[73px] justify-center items-center">
        <Link
          to="https://researcher.vulfosec.com/register"
          target="_blank"
          className="flex items-center transition-colors"
        >
          <div className="text-center  text-white text-lg md:text-xl mx-3 lg:mx-0">
              No bug bounty program yet? Start free and fortify security with
              our tailored solutions - safeguard your digital landscape.
            <span className="inline-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler justify-end icon-tabler-external-link relative top-1.5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="white"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                <path d="M11 13l9 -9" />
                <path d="M15 4h5v5" />
              </svg>
            </span>
          </div>
        </Link>
      </div>
    </>
  );
};
