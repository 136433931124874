import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Hero = () => {
  const [isHovered, setHovered] = useState(false);
  const [isHoveredTwo, setHoveredTwo] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };
  const handleHoverTwo = () => {
    setHoveredTwo(true);
  };

  const handleLeaveTwo = () => {
    setHoveredTwo(false);
  };

  return (
    <div className="  mt-[50px]  mx-auto  max-w-screen-xl">
      <div className="mt-16 flex flex-col items-center justify-center">
        <div className="text-center">
          <div>
            <h1 className="text-[#545454] mb-7 text-4xl md:text-5xl lg:text-6xl font-extrabold">
              {" "}
              <span className="">Diversified Bug Bounty Platform</span>
              <br />
              <span className="text-[#004aad]  ">
                Integrated With Cybersecurity Marketplace Catering to
                CyberSpace.
                {/* <br /> Providers. */}
              </span>
            </h1>
          </div>
          <p className="text-black text-opacity-75 mx-10 lg:mx-0 lg:text-[28px]  font-semibold">
            Unleash comprehensive security solutions as our diverse bug bounty
            platform integrates seamlessly, catering to the cybersecurity needs
            of the expansive realm of Cyberspace.
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-7 mt-8">
          <Link to="https://company.vulfosec.com" target="_blank">
            <button
              style={{
                // border: "1px solid #191A23",
                boxShadow: isHovered ? "none" : "3px 4px 0px 0px #191A23",
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              className="p-5 bg-blue-800 text-white text-xl font-bold font-inter  rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
            >
              Proactive Threat Discovery
            </button>
          </Link>

          <Link to="https://researcher.vulfosec.com" target="_blank">
            <button
              onMouseEnter={handleHoverTwo}
              onMouseLeave={handleLeaveTwo}
              style={{
                // border: "1px solid #191A23",
                boxShadow: isHoveredTwo ? "none" : "3px 4px 0px 0px #191A23",
              }}
              className="p-5 bg-blue-800 text-white text-xl font-bold font-inter leading-7 rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300 mt-4 md:mt-0"
            >
              Join Researcher Community
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
