import React from "react";
import Cardimg1 from "../assets/Cardimg1.png";
import Cardimg2 from "../assets/Cardimg2.png";
import Cardimg3 from "../assets/Cardimg3.png";
import Cardimg4 from "../assets/Cardimg4.png";
import Cardimg5 from "../assets/Cardimg5.png";
import Cardimg6 from "../assets/Cardimg6.png";

export const Cards = () => {
  const cards = [
    {
      title: "Proactive Vulnerability assessment",
      description:
        "Discover and report security loopholes in your digital infrastructure with our bug bounty platform, empowering researchers to enhance your cyber defenses.",
      imgUrl: Cardimg1,
    },
    {
      title: "Collaborate Securely",
      description:
        "Foster a secure collaboration environment between researchers, your company, and vendors to efficiently address and remediate identified vulnerabilities.",
      imgUrl: Cardimg2,
    },
    {
      title: "Comprehensive Reporting",
      description:
        "Receive detailed and actionable reports from our bug bounty platform, providing transparent insights into discovered vulnerabilities and recommended solutions.",
      imgUrl: Cardimg3,
    },
    {
      title: "Expert-Led Assessments",
      description:
        " Engage with a team of skilled researchers and ethical hackers, ensuring a thorough and professional assessment of your digital assets for potential security weaknesses.",
      imgUrl: Cardimg4,
    },
    {
      title: "Real-Time Updates for Researchers",
      description:
        "Researchers receive instant updates on their reported vulnerabilities, fostering a dynamic and collaborative environment between security experts and companies for swift resolution.",
      imgUrl: Cardimg5,
    },
    {
      title: "Flexible Program Hosting",
      description:
        "Companies have the freedom to host bug bounty programs tailored to their specific needs, ensuring flexibility and compliance with regulations for a customized and effective cybersecurity approach.",
      imgUrl: Cardimg6,
    },
  ];

  return (
    <>
      <div className="text-gray-600 my-10 mt-[150px] lg:mt-[150px] mb-[80px] text-center text-7xl font-extrabold self-center max-md:max-w-full max-md:text-4xl">
        What <span className="text-blue-800">We Offer</span>
      </div>
      <div className="grid grid-cols-1 m-10 md:grid-cols-1 lg:grid-cols-2 gap-y-[54px] justify-center items-center h-full">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex flex-col items-center lg:items-start max-w-[600px] mx-auto max-md:w-full"
          >
            <div>
              <img
                loading="lazy"
                src={card.imgUrl}
                alt={`img${index + 1}`}
                className="aspect-[1.01] justify-center w-[123px] h-[120px]"
              />
            </div>
            <div className="self-stretch text-zinc-900 max-lg:text-center lg:text-start text-[17px] md:text-[22px] lg:text-[32px] font-semibold mt-5">
              {card.title}
            </div>
            <div className="text-justify text-neutral-600 px-5 -ml-4  text-sm md:text-lg lg:text-xl mt-3">
              {" "}
              {card.description}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
