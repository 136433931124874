import React, { useState } from "react";
import Process from "../assets/Process.png";

export const OurProcess = () => {
  const accordionItems = [
    {
      title: "Onboarding",
      content:
        "Register your company on our platform, defining your bug bounty program's scope, rules, and engagement parameters.",
    },
    {
      title: "Program Launch",
      content:
        "Initiate your bug bounty program, inviting researchers to assess your digital assets within the predefined scope.",
    },
    {
      title: "Vulnerability Management",
      content:
        "Receive and analyze vulnerability submissions, allowing your team to prioritize and manage reported issues effectively.",
    },
    {
      title: "Vulnerability Resolution",
      content:
        "After receiving comprehensive vulnerability reports, initiate the internal resolution process, concluding the bug bounty cycle while progressing to other program assessments.",
    },
  ];

  return (
    <>
      <div className="flex flex-col mt-4 justify-center md:mx-20">
        <div className="text-gray-700 text-5xl font-extrabold text-center mx-10 mt-20 max-md:max-w-full max-md:text-4xl max-md:mt-6 lg:text-7xl">
          Our Working <span className="text-blue-800">Process</span>
        </div>

        <div className="text-black text-opacity-70 text-center mx-4 text-xl font-semibold self-center mt-4 max-md:max-w-full lg:text-2xl">
          Step-by-Step Guide to Achieving Your Business Goals
        </div>

        <div className=" flex flex-col md:flex-row  justify-center md:mt-3">
          <div className="flex justify-center">
            <img
              loading="lazy"
              src={Process}
              alt=""
              className=" w-full"
            />
          </div>
          <div className="flex justify-center items-center ">
            <div className=" border-[20px] rounded-lg border-[#ebf1f8]">
              <Accordion items={accordionItems} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-[10px] border-[#ebf1f8]">
      <div
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-xl font-semibold">{title}</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-square-rounded-chevron-down-filled"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2c-.218 0 -.432 .002 -.642 .005l-.616 .017l-.299 .013l-.579 .034l-.553 .046c-4.785 .464 -6.732 2.411 -7.196 7.196l-.046 .553l-.034 .579c-.005 .098 -.01 .198 -.013 .299l-.017 .616l-.004 .318l-.001 .324c0 .218 .002 .432 .005 .642l.017 .616l.013 .299l.034 .579l.046 .553c.464 4.785 2.411 6.732 7.196 7.196l.553 .046l.579 .034c.098 .005 .198 .01 .299 .013l.616 .017l.642 .005l.642 -.005l.616 -.017l.299 -.013l.579 -.034l.553 -.046c4.785 -.464 6.732 -2.411 7.196 -7.196l.046 -.553l.034 -.579c.005 -.098 .01 -.198 .013 -.299l.017 -.616l.005 -.642l-.005 -.642l-.017 -.616l-.013 -.299l-.034 -.579l-.046 -.553c-.464 -4.785 -2.411 -6.732 -7.196 -7.196l-.553 -.046l-.579 -.034a28.058 28.058 0 0 0 -.299 -.013l-.616 -.017l-.318 -.004l-.324 -.001zm-3.707 8.293a1 1 0 0 1 1.32 -.083l.094 .083l2.293 2.292l2.293 -2.292a1 1 0 0 1 1.32 -.083l.094 .083a1 1 0 0 1 .083 1.32l-.083 .094l-3 3a1 1 0 0 1 -1.32 .083l-.094 -.083l-3 -3a1 1 0 0 1 0 -1.414z"
            fill="currentColor"
            stroke-width="0"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="p-4">
          <p className="text-gray-600">{content}</p>
        </div>
      )}
    </div>
  );
};

const Accordion = ({ items }) => {
  return (
    <div className="w-[280px] lg:w-[450px]">
      {items.map((item, index) => (
        <AccordionItem key={index} {...item} />
      ))}
    </div>
  );
};
