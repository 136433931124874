import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import axios from "axios";
import Cookies from "js-cookie";
import BlogListing from "./Components/BlogListing/BlogListing";
import BlogPage from "./Components/BlogPage/BlogPage";
import About from "./Components/About";

export default function App() {
  const expires = 1000 * 60 * 60 * 24 * 15;
  useEffect(() => {
    const uidCookie = Cookies.get("_UID");

    if (!uidCookie) {
      document.cookie = `_UID=vdvnvsvnksvnskvnsksnvksvnskvnskvnskvnvk; path=/; expires=${new Date(
        Date.now() + expires
      ).toUTCString()}; SameSite=None; Secure`;
    }
  }, []);
  // axios.defaults.baseURL = "http://localhost:8080";
  axios.defaults.baseURL = "https://api.vulfosec.com";
  axios.defaults.withCredentials = true;
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Blogs" element={<BlogListing />} />
          <Route path="/blog/:slug" element={<BlogPage />} />
          <Route path="/About" element={<About/>} />
        </Routes>
      </Router>
    </>
  );
}
