import React from "react";
import { Hero } from "../Components/Hero";
import { Hero2 } from "../Components/Hero2";
import { Cards } from "../Components/Cards";
import { Middle } from "../Components/Middle";
import { Middle2 } from "../Components/Middle2";
import { OurProcess } from "../Components/OurProcess";
import Middle3 from "../Components/Middle3";
import { MidBottom } from "../Components/MidBottom";
import "../App.css";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Head } from "../Components/Head";
import {Contact} from "../Components/Contact";
// import{ Testimonials } from "../Components/Testimonials/Testimonials";
import BlogsLanding from "../Components/BlogsLanding/BlogsLanding";
// import Blogs from ".../Components/Contactogs";

export default function Homepage() {
  return (
    <>
      <div className=" container-md">
        <NavBar />
        <Head />
        <Hero />
        <Hero2 />
        <Cards />
        <Middle />
        <Middle2 />
        <OurProcess />
        <Middle3 />
        <MidBottom />
        {/* <Blogs/> */}
        {/* <Testimonials/> */}
        <BlogsLanding isShow={false} isShow1={true}/>
        <Contact/> 
        <Footer />
      </div>
    </>
  );
}
