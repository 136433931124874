import React, { useState, useEffect } from "react";
import "./BlogsLanding.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const BlogsLanding = ({ isShow, isShow1 }) => {
  const [blogs, setBlogs] = useState([]);
  const [selectedOption, setSelectedOption] = useState("most recent");

  useEffect(() => {
    axios
      .get("/getBlogs?isPublished=true")
      .then((response) => {
        const blogData = response.data.blogs;
        setBlogs(blogData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Filter blogs based on the selected category
  const filteredBlogs =
    selectedOption === "most recent"
      ? blogs
      : blogs.filter((blog) => blog.category === selectedOption);

  const staticCards = [
   
    
  ];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  });
  return (
    <div className="recent-blogs  mt-[60px] w-full  ">
      {isShow && (
        <div
          className="header h-[30px] mx-24 mb-40 sm:mb-12 gap-5"
          data-aos="fade-left"
        >
          <h2 className="title text- lg:text-xl main-heading px-3 md:px-6 lg:py-6">Recent Blogs</h2>
          <div className=" flex justify-center ">
            <select
              className=" outline-none p-1 rounded-lg   bg-[#ebf1f8]"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="most recent" className="desc ">
                Most Recent Blogs
              </option>
              <option value="VAPT">VAPT</option>
              <option value="Cloud">Cloud</option>
              <option value="SOC">SOC</option>
              <option value="Firewall">Firewall</option>
              <option value="Network Security">Network Security</option>
              <option value="Scada">Scada</option>
              <option value="IOT">IOT</option>
              <option value="OT">OT</option>
              <option value="Regulations and Compliance">
                Regulations and Compliance
              </option>
              <option value="Audit">Audit</option>
              <option value="IT Security">IT Security</option>
              <option value="API security">API Security</option>
              <option value="Endpoint Security">Endpoint Security</option>
              <option value="Incident Response">Incident Response</option>
              <option value="Threat Intelligence">Threat Intelligence</option>
              <option value="Tools">Tools</option>
              <option value="Automation">Automation</option>
              <option value="GRC">GRC</option>
              <option value="Bug Bounty">Bug Bounty</option>
              <option value="Marketplace">Marketplace</option>
              <option value="Network Security">Network Security</option>
            </select>
          </div>
        </div>
      )}

      {isShow1 && (
        <div className="flex flex-col items-center">
          <Link to="/Blogs">
            <div className="flex items-center justify-center mb-4">
              <p className="text-4xl md:text-5xl lg:text-7xl  font-extrabold text-[#545454]">
                Explore <span className="text-[#044aad]">Blogs</span>
              </p>
            </div>
          </Link>

          {/* <Link to="/Blogs">
            <div className="mb-4">
              <p className="text-[#044aad] md:text-[24px] mb-16 md:mb-0 lg:absolute lg:right-28 font-semibold underline">
                Subscribe Our News Letter
                <KeyboardDoubleArrowRightIcon />
              </p>
            </div>
          </Link> */}
        </div>
      )}
      <div className="cards flex text-center lg:text-start text-xl lg:text-2xl md:m-20">
        {staticCards.length === 0 ? (
          <p className="main-heading mt-5 md:mt-0 ">
            No blogs available for the {selectedOption} category.
          </p>
        ) : (
          staticCards.map((card) => (
            <div
              className="col-lg-4 card1 "
              key={card.id}
              data-aos="zoom-in"
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/blog/${card.slug}`}
              >
                <div className="heading">
                  <h2 className= " text-[27px] lg:text-4xl main-heading">{card.title}</h2>
                  <h4 className="text-[17px]   mt-2 lg:mt-4 lg:text-lg desc">{card.category}</h4>
                  <h5 className="text-[15px] pt-2 lg:text-md">{card.time}</h5>
                </div>
                <div className="hr my- lg:my-4  "></div>
                <div className="content text-[18px] -py-10 md:text-xl lg:text-2xl  ">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: card.description,
                    }}
                    className="desc"
                  ></p>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BlogsLanding;
