import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/navImage.png";
// import logo2Text from "../Assests/logo2Text.png";
import { Link } from "react-router-dom";
// import SearchBar from "./SearchBar";

function NavList() {
  return (
    <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="as"
        variant="large"
        color="blue-gray"
        className="p-2 font-semibold text-lg transition transform text-black "
      >
        <Link to="/">Home</Link>
      </Typography>
      <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-2 font-semibold text-lg transition transform text-black "
      >
        <Link
          to="https://company.vulfosec.com"
          target="_blank"
          className="flex items-center transition-colors font-['inter']"
        >
          For Companies
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-2 font-semibold  text-lg text-black"
      >
        <Link
          to="https://researcher.vulfosec.com"
          target="_blank"
          className="flex items-center transition-colors"
        >
          Researchers
        </Link>
      </Typography>
      {/* <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-2 font-semibold text-lg text-black"
      >
        <Link to="/About" className="flex items-center transition-colors">
          About Us
        </Link>
      </Typography> */}
      <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-2 font-semibold text-lg text-black"
      >
        <Link to="/Blogs" className="flex items-center transition-colors">
          Blogs
        </Link>
      </Typography>
    </ul>
  );
}

export default function NavBar(props) {
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="sticky -top-2 z-50 py-2">
        <Navbar className="px-6 max-w-screen-3xl py-2 shadow-none rounded-none border-none">
          <div className="flex items-center justify-between text-blue-gray-900">
            <Typography
              as="a"
              href="/"
              variant="h6"
              className="mr-4 cursor-pointer py-1.5 flex items-center"
            >
              <img src={logo} alt="logo" style={{ height: "50px" }} />
            </Typography>
            <div className="hidden lg:flex lg:flex-col">
              <NavList />
              {/* <SearchBar /> */}
            </div>

            <IconButton
              variant="text"
              className="  text-[#545454]  relative -top-4 -left-10 lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <XMarkIcon className="h-8 w-8" strokeWidth={2} />
              ) : (
                <Bars3Icon className="h-8 w-8" strokeWidth={2} />
              )}
            </IconButton>
          </div>
          <Collapse open={openNav}>
            <NavList />
          </Collapse>
          {props.border == "true" && <hr />}
        </Navbar>
      </div>
    </>
  );
}
