import React from "react";
import SecInt from "../assets/SecureInternet.png";
import Line from "../assets/Line 1.png";

export const MidBottom = () => {
  return (
    <>
      <div className="bg-[#004aad] pb-10">
        <div className="flex flex-col justify-center  lg:flex-row ">
          <div className="flex flex-col mx-10 justify-center md:ml-20 xl:-mt-7">
            <div className="text-white mt-[43px] font-extrabold text-[50px]">
              Securing the Internet
            </div>
            <div className="text-white text-[25px]  lg:w-[80%] font-semibold">
              Strengthening the Internet through a collaborative
              community of diverse companies and researchers,
              ensuring a united front forrobust cybersecurity solutions.
            </div>
            <div className="mt-7 flex gap-">
              <div className="">
                <div className="text-white text-[35px] font-extrabold">
                  2500+
                </div>
                <div className="text-white text-[30px] font-extrabold">
                  Researchers on Board
                </div>
              </div>
              {/* <div className="hidden md:block border-2 border-white rounded-full "></div> */}
              {/* <div className="">
                <div className="text-white text-2xl font-extrabold">
                  20,000+
                </div>
                <div className="text-white text-xl font-extrabold mt-2">
                  Companies on Board
                </div>
              </div> */}
            </div>
          </div>

          <div className="flex justify-center  items-center">
            <div className=" ">
              <img
                src={SecInt}
                alt=""
                className=" relative mb-[-40px] lg:w-[900px]  xl:-left-[140px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* <div className=" ">
            

            <div className="flex flex-col md:w-1/2 mt-5 md:self-start">
              <div className="text-white text-2xl font-extrabold">20,000+</div>
              <div className="text-white text-xl font-extrabold mt-2">
                Researchers on Board
              </div>
            </div>
          </div> */
}
