import React, { useState } from "react";
import Con from "../assets/ConnectWithUs.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const Contact = () => {
  const generateSixDigitNumber = () => {
    const min = 100000; // Smallest 6-digit number
    const max = 999999; // Largest 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailInput = event.target.elements.email.value.trim();
      if (!emailRegex.test(emailInput)) {
        toast.error("Please enter a valid email address");
        return;
      }

      const formData = new FormData(event.target);
      let contactData = {};
      formData.forEach((value, key) => {
        contactData[key] = value;
      });
      console.log(contactData);
      contactData.contactFormId = generateSixDigitNumber();
      const createdContactEntry = await axios.post(
        "/createContactForm",
        contactData
      );

      if (createdContactEntry) {
        toast.success("Contact form was submitted successfully!!");
        event.target.reset();
      }
    } catch (err) {
      toast.error("Contact form was not submitted successfully!!!");
      console.error(err);
    }
  };

  const accordionItems = [
    {
      title: "What are the benefits of using a bug bounty platform?",
      content:
        "Bug bounty platforms provide proactive vulnerability assessment, access to a diverse talent pool of global security researchers, cost-effectiveness through pay-per-find models, and contribute to an improved security posture by addressing vulnerabilities before exploitation.",
    },
    {
      border: "top",
      title: "What are some of the features of a typical bug bounty platform?",
      content:
        "Typical features of a bug bounty platform include a secure reporting system, tools for tracking and managing vulnerabilities, communication channels between companies and researchers, and a reward system for researchers who discover and report vulnerabilities.",
    },
    {
      border: "top",
      title: "How much does it cost to use a bug bounty platform?",
      content:
        "The cost of using a bug bounty platform can vary depending on the size and complexity of the program. Some platforms charge a monthly or annual fee, while others charge a percentage of the rewards paid out to researchers.",
    },
    {
      border: "top",
      borderb:"bottom",
      title: "Is using a bug bounty platform secure?",
      content:
        "Bug bounty platforms can be a secure way to find and fix vulnerabilities, but it is important to choose a reputable platform with a strong security track record. Companies should also take steps to protect their own systems from being compromised by malicious actors.",
    },
  ];

  return (
    <>
      <Toaster />
      <div className="bg-blue-800 bg-opacity-10  self-stretch flex w-full pb-40 flex-col justify-center items-center  px-16 py-12 max-md:max-w-full max-md:mt-10 max-md:px-5 ">
        <div>
          <div className="text-[#545454] text-2xl flex flex-col  font-bold text-center lg:mt-20 max-md:max-w-full md:text-4xl lg:text-7xl mb-4">
            <span className="lg:mb-5">Frequently </span>
            <span className=" text-[#004aad]">
               Asked Questions
            </span>
          </div>
          <div>
            <div className="flex mt-[40px]  ">
              <div className="rounded-lg">
                <Accordion items={accordionItems} />
              </div>
            </div>
          </div>
        </div>
        
        <div
          className="flex w-full max-w-[1211px] flex-col items-center mt-40"
        >
          <div className="text-[#545454] mb-10 text-7xl font-bold max-md:max-w-full max-md:text-4xl">
            Contact <span className="text-[#004aad]"> Us</span>
          </div>
          <div className="self-stretch  ">
            <form
              onSubmit={handleSubmit}
              className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0"
            >
              <div className="flex flex-col items-stretch w-[556px] max-md:w-full max-md:ml-0">
                <label
                  htmlFor="name"
                  className="text-neutral-800 text-base leading-5 max-md:max-w-full"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="text-zinc-500 text-lg whitespace-nowrap border bg-white justify-center mt-1.5 pl-8 pr-16 py-5 rounded-2xl border-solid border-blue-800 items-start max-md:max-w-full max-md:px-5"
                  required
                />
                <label
                  htmlFor="email"
                  className="text-black text-base leading-5 mt-6 max-md:max-w-full"
                >
                  Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="text-zinc-500 text-lg whitespace-nowrap border bg-white justify-center mt-1.5 pl-8 pr-16 py-5 rounded-2xl border-solid border-blue-800 items-start max-md:max-w-full max-md:px-5"
                  required
                />
                <label
                  htmlFor="message"
                  className="text-black text-base leading-5 mt-6 max-md:max-w-full "
                >
                  Message*
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="text-zinc-500 text-lg whitespace-nowrap border h-4 overflow-hidden bg-white mt-1.5 pl-8 pr-16 pt-5 pb-32 rounded-2xl border-solid border-blue-800 items-start max-md:max-w-full max-md:pb-10 max-md:px-5"
                  required
                ></textarea>
                <button
                  type="submit"
                  className="text-white text-center text-xl leading-3 whitespace-nowrap justify-center items-center bg-blue-800 mt-14 px-16 py-5 rounded-2xl max-md:max-w-full max-md:mt-7 max-md:px-5"
                >
                  Send Message
                </button>
              </div>
              <div className="flex flex-col items-stretch w-[53%] ml-5 max-md:w-full max-md:ml-0">
                <img
                  loading="lazy"
                  src={Con}
                  alt=""
                  className="aspect-[1.08] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-6"
                />
              </div>
            </form>
          </div>
        </div>

      </div>
    </>
  );
};
const AccordionItem = ({ border,borderb, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={` ${
        border == "top" ? "border-t" : ""
      } ${borderb == "bottom" ? "border-b":""} border-[#545454] w-[300px]  md:w-[680px] lg:w-[760px] mx-0 jutify-center `}
    >
      <div
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className=" md:text-[20px] lg:text-[25px]  font-semibold">{title}</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 9l6 6l6 -6" />
        </svg>
      </div>
      {isOpen && (
        <div className="p-2 md:p-4 ">
          <p className="text-gray-600 text-[18px] mx-3 lg:text-[24px]">{content}</p>
        </div>
      )}
    </div>
  );
};

const Accordion = ({ items }) => {
  return (
    <div className=" ">
      {items.map((item, index) => (
        <AccordionItem key={index} {...item} />
      ))}
    </div>
  );
};
