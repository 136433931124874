import React from "react";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";

export const Hero2 = () => {
  return (
    <div className="grid grid-cols-1 m-12 pl-3 mt-[100px] sm:grid-cols-2 max-lg:grid-cols-2 lg:grid-cols-4 gap-6  items-center justify-center md:mt-[150px] ">
      <img className="grayscale " src={image1} alt="img1" />
      <img className="grayscale " src={image2} alt="img2" />
      <img className="grayscale " src={image3} alt="img3" />
      <img className="grayscale " src={image4} alt="img4" />
    </div>
  );
};
