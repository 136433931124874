import React from "react";
import Mid from "../assets/Middle3img.png";
import Mid2 from "../assets/Middle3img2.png";
import Mid3 from "../assets/Middle3img3.png";
import Mid4 from "../assets/Middle3img4.png";

const Middle3 = () => {
  const imageStyle = {
    filter: "grayscale(0)", // Add this line to remove the grayscale effect
  };

  return (
    <>
      <div className="text-gray-700 text-4xl font-extrabold text-center mt-20 md:text-5xl lg:text-7xl">
        Our <span className="text-blue-800">Partners</span>
      </div>
      <div className="text-black text-opacity-70 text-center text-lg md:text-2xl lg:text-3xl xl:text-3xl font-bold self-center mt-4 md:mt-6 lg:mt-8 xl:mt-10">
        Step-by-Step Guide to Achieving Your Business Goals
      </div>

      <div className=" flex justify-center sm:flex mb-44 md:justify-center  px-10  mt-20  max-md:max-w-full max-md:flex-wrap max-md:mt-10">
        <img
          loading="lazy"
          src={Mid}
          alt=""
          style={imageStyle} // Apply the style here
          className="object-contain object-center w-[300px] h-[170px] overflow-hidden self-center"
        />
        <img
          loading="lazy"
          src={Mid2}
          alt=""
          style={imageStyle} // Apply the style here
          className="object-contain object-center h-[170px] shadow-sm overflow-hidden self-stretch shrink-0 max-w-full"
        />
        <img
          loading="lazy"
          src={Mid3}
          alt=""
          style={imageStyle} // Apply the style here
          className="object-contain object-center h-[170px] overflow-hidden self-center shrink-0 max-w-full"
        />
        <img
          loading="lazy"
          src={Mid4}
          alt=""
          style={imageStyle} // Apply the style here
          className="object-contain object-center h-[170px] overflow-hidden self-center shrink-0 max-w-full"
        />
      </div>
    </>
  );
};

export default Middle3;
