import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./BlogPage.scss";
import axios from "axios";
import Footer from "../Footer";
import NavBar from "../NavBar";
import defaultLogo from "../../assets/img/defaultBlogLogo.png";

class BannerImg extends Component {
  render() {
    return (
      <div className="bannerImg container-md my-5">
        <div className="banner-img w-100 p-1" data-aos="zoom-in">
          <img src={this.props.img} alt="" />
        </div>
      </div>
    );
  }
}

class Component1 extends Component {
  render() {
    return (
      <div className="container-md">
        <div
          className="card0 d-flex justify-content-between"
          data-aos="zoom-in"
        >
          <div className="content">
            <h3 className="title text-4xl main-heading ">
              {this.props.heading}
            </h3>
            <h5 className="vapt px-1 py-1 mt-2 text-lg desc">
              {this.props.category}
            </h5>
            {/* edited by sac */}
          </div>
          <div className="d-md-flex d-none">
            {/* <img src={img1} alt="" /> */}
          </div>
        </div>
      </div>
    );
  }
}

class BlogSection extends Component {
  render() {
    return (
      <div className="container-md my-5 ">
        <div className="blog-section py-4 px-10" data-aos="zoom-in">
          <p
            className="mt-3 main-heading"
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          ></p>
          {/* <div className='my-4'>
            <img src={this.props.img} alt='' />
          </div> */}
          <p
            className="mt-3 main-heading"
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          ></p>
        </div>
      </div>
    );
  }
}

function BlogPage() {
  const [toggle, setToggle] = useState(false);
  const [blog, setBlog] = useState([]);

  const { slug } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(slug);
        const response = await axios.get(`/getBlogs?slug=${slug}`);
        if (response) {
          let allBlogs = response.data.blogs;
          allBlogs = allBlogs.map((blog) => ({
            ...blog,
            blogImgPath: `https://api.vulfosec.com/${blog.blogImgPath.replace(
              "\\",
              "/"
            )}`,
          }));
          console.log(allBlogs);
          setBlog(allBlogs);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render
  const change = (data) => {
    setToggle(data);
  };
  return (
    <>
      <NavBar
        isBg="true"
        isServices={true}
        isBlog={false}
        isWaitlist={true}
        toggle={toggle}
        settoggle={change}
      />
      <div className="flex flex-col justify-center mx-5 md:mx-20">
        {blog.map((blogItem) => (
          <React.Fragment key={blogItem.title}>
            <BannerImg
              img={blogItem?.blogImgPath ? blogItem?.blogImgPath : defaultLogo}
            />
            <Component1 heading={blogItem.title} category={blogItem.category} />
            <BlogSection
              title={blogItem.title}
              description={blogItem.description}
              content={blogItem.mainContent}
            />
          </React.Fragment>
        ))}
        {/* <Blogs /> */}
        {/* <Proposal heading={proposal_heading} content={proposal_content} /> */}
      </div>
      <Footer />
    </>
  );
}

export default BlogPage;
