import React, { Component } from "react";
// import logo from "../../assets/vulfosec-light.webp";
import "./bloglisting.scss";
// import img from "../../assets/tokyo-magnifier-web-search-with-elements 2.webp";
// import { Link } from "react-router-dom";
// import NavBar from "../NavBar.js";
import Footer from "../Footer.js";
// import { NavigationBar } from "../NavBar.js/index.js";
import NavBar from "../NavBar.js";
import BlogsLanding from "../BlogsLanding/BlogsLanding.js";

export class BlogListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }

  change = (data) => {
    this.setState({ toggle: data });
  };

  render() {
    // const { toggle } = this.state;
    return (
      <>
        <div className=" container-lg">
          <NavBar />

          {/* <Component1 /> */}
          <BlogsLanding isShow={true} isShow1={false} />
          <Footer />
        </div>
      </>
    );
  }
}

export default BlogListing;
